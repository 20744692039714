import * as React from "react";
import {useEffect, useRef} from "react";
import CloseIcon from "./CloseIcon";

interface Props {
  source: string,
  onBack: Function,
}

enum ControlCodes {
  PLAY = 415,
  PAUSE = 19
}

export default function VideoPlayer ({source, onBack}: Props) {
  const playerRef = useRef<HTMLVideoElement>(null);

  const listenRemote = function(event: KeyboardEvent) {
    console.log(event.keyCode);

    if (!playerRef.current) {
      return;
    }

    switch (event.keyCode) {
      case ControlCodes.PLAY:
        playerRef.current.play();
        break;
      case ControlCodes.PAUSE:
        playerRef.current.pause();
        break;
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', listenRemote);

    return () => {
      document.removeEventListener('keydown', listenRemote);
    }
  }, []);

  return (
    <div>
      { process.env.NODE_ENV === 'development' ?
        <a
          href="#"
          className="block p-2 bg-white fixed top-4 right-4 rounded-lg z-50"
          onClick={(e) => {
            e.preventDefault();
            onBack();
          }}
        >
          <CloseIcon></CloseIcon>
        </a> : ''
      }
      <video
        src={source}
        ref={playerRef}
        className="absolute z-40 right-0 bottom-0 left-0 top-0 object-cover w-full h-full"
        muted
        loop
        autoPlay
      >
      </video>
    </div>
  );
}
