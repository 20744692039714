import * as React from "react";
import {useState, useEffect} from "react";
import Code from "./components/Code";
import VideoPlayer from "./components/VideoPlayer";
import supabase from "./supabase";
import Progress from "./components/Progress";

async function getCode() {
  const code = Math.floor(100000 + Math.random() * 900000);

  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const {error, data} = await supabase.from('devices')
    .insert({
      code,
      expires_at: tomorrow
    })
    .select()
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data.code;
}

async function persist (key: string, cb: Function): Promise<string> {
  const persistedValue = window.localStorage.getItem(key);

  if (persistedValue) {
    return persistedValue;
  }

  const value = await cb();

  window.localStorage.setItem(key, value)

  return value;
}

function stopPersisting (key: string) {
  window.localStorage.removeItem(key);
}

type RenderStatusResponse = {
  ok: true,
  done: boolean,
  outputFile: string | null,
  progress: number,
  screen_id: string,
} | {
  ok: false,
  error: string,
}

async function getRenderStatus(code: number): Promise<RenderStatusResponse> {
  const url = process.env.FESTIVESCREEN_WEBSITE + '/api/devices/' + code + '/render-status';

  const data = await fetch(url);
  return await data.json();
}

export default function App() {
  const [source, setSource] = useState<string>();
  const [code, setCode] = useState<number>();
  const [progress, setProgress] = useState<number>();

  const updateCode = async () => {
    try {
      const code = await persist('deviceCode', getCode);

      setCode(parseInt(code));
    } catch (error) {
      console.error(error);
    }
  }

  const resetCode = () => {

    console.log('resetCode');
    stopPersisting('deviceCode')

    setCode(undefined);
    setSource(undefined);
    setProgress(undefined);
    if (window.location.hash) {
      window.location.hash = '';
    }

    setTimeout(async () => {
      await updateCode();
    }, 100);
  }

  useEffect(() => {
    updateCode();

    window.onhashchange = () => {
      if (!window.location.hash) {
        resetCode();
      }
    };
  }, []);

  useEffect(() => {
    if (!code) {
      return;
    }

    const interval = setInterval(async () => {
      try {
        const response = await getRenderStatus(code);

        if (response.ok) {
          setProgress(response.progress);
          if (response.done && response.outputFile) {
            window.location.hash = 'VIDEO';
            setSource(response.outputFile);
            clearInterval(interval);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    }
  }, [code])

  return <>
    {code && !source && !progress && <Code code={code}></Code>}
    {!source && progress && <Progress progress={progress}></Progress>}
    {source && <VideoPlayer source={source} onBack={resetCode}></VideoPlayer>}
    {
      process.env.NODE_ENV === 'development' ?
      <div className="bg-black text-sm opacity-60 px-2 text-white fixed bottom-0 right-0 z-50">{process.env.NODE_ENV}</div> :
      ''
    }
  </>;
}