import * as React from "react";

interface Props {
  progress: number,
}

export default function Progress({progress}: Props) {
  const progressInt = Math.floor(progress * 100);

  return <div className="flex w-[400px] h-full mx-auto justify-center flex-col text-center">
    <div className="text-white">Rendering your video</div>
    <span id="progress-text" className="text-3xl mb-4 text-white drop-shadow">{progressInt}%</span>
    <progress
      className="progress progress-primary bg-white animate-pulse"
      value={progressInt}
      max="100"
      role="progressbar"
    ></progress>
  </div>
}
