import * as React from "react";

const logo = new URL('../images/logo.svg', import.meta.url);

export default function Code({code}: {code: number}) {

  return (
    <div className="flex w-[800px] h-full mx-auto justify-center flex-col">
      <div className="relative">
        <div className="absolute opacity-40 bg-white w-full h-full z-10 rounded-xl"></div>
        <div className="relative z-20 p-16">
          <div className="text-center">
            <img className="inline" src={logo.toString()} />
          </div>
          <div className="flex flex-col">
            <div className="flex justify-center p-10">
              <div className="bg-white px-10 pt-4 pb-3 rounded-full font-bold text-3xl">
                {code}
              </div>
            </div>
            <div className="text-center text-2xl">
              Open your screen in <span className="text-[#2fb59c]">https://festivescreen.com</span><br />
              click on "Cast to TV"<br/>
              and enter your OTP code to associate your device
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
